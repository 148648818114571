/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, useNavigate, } from 'react-router-dom';
import Header from './Components/Menu/Header';
import { useEffect, useState } from 'react';
import Navegacao from './Components/Menu/Navegacao';
import './Components/portal.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getUser } from './firebase';


function Root() {
  const navigate = useNavigate();
  const [User, setUser] = useState({});
  let hasAuth = false;
  function authFirebase() {
    const auth = getAuth();
    if (!hasAuth) {
      hasAuth = true;
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          localStorage.setItem('user_uid', user.uid)
          await getUser().then((usuario) => {
            localStorage.setItem('usuario', usuario)
            setUser(usuario);
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
        } else {
          navigate('/acesso');
          // ClearCache();
          // eslint-disable-next-line no-console
          console.log('Deslogado!');
        }

      });
    }
  }

  /* async function verificardadosUsuario() {
    try {
      const usuario = JSON.parse(localStorage.getItem('usuario'));
      setUser(usuario);
      if (usuario !== null) {
        if (usuario.nome === null) {
          navigate(`/Acesso/ComplUser/${usuario.id}`);
        }
      } else {
        navigate('/acesso');
      }
    } catch (error) {
      //console.log(error);
    }
  } */


  useEffect(() => {
    //verificardadosUsuario();
    //  authFirebase()
  }, []);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);

  const handleMouseEnter = () => {
    setSidebarExpanded(true);
  };

  const handleMouseLeave = () => {
    setSidebarExpanded(false);
  };
  return (
    <Container fluid>
      <Row >
        <Header User={User} />
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Navegacao
            User={User}
            classe={isSidebarExpanded ? 'expanded' : ''}
            ico={!!isSidebarExpanded}
          />
        </div>
        <Col
          className={`main-content ${isSidebarExpanded ? '' : 'expanded'}`}
        >
          <div className='linhas'>
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Root;
