import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './error-page';
import Root from './root';
import Acesso from './routes/Acesso';
import Dashboard from './routes/Dashboard';
import Login from './routes/Login';
import ListaOpe from './07_Operacao/7.1_lista';
import OpMaritma from './07_Operacao/7.2_operacao_cab';
import ResumoOpe from './07_Operacao/7.7_operacao_resumo';
import Statement from './07_Operacao/7.8_operacao_sof';
import RelCop from './07_Operacao/7.6_operacao_cop';
import Integracao from './05_Documento/5.1_Integracao';
import Empresas from './03_Empresa/3.1_Empresas';
// import CadOpe from './07_Operacao/7.3_operacao_cadastro';
import Documentos from './05_Documento/5.2_Doctos';
import CadEscala from './05_Documento/5.3.1_Doctos_cad_Escala';
import DescMaritma from './07_Operacao/7.5_operacao_descarga';
import ManifestoForm from './05_Documento/5.3.2_Doctos_cad_manifesto';
import CeMercanteForm from './05_Documento/5.3.3_Doctos_cad_ce';


const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <ListaOpe /> },
      { path: 'Dashboard', element: <ListaOpe />, },
      { path: 'Integracoes', element: <Integracao />, },
      { path: 'ListaOpe', element: <ListaOpe />, },
      { path: 'ListaOpe/cadastro', element: <CadEscala />, },
      { path: 'ListaOpe/:Escala', element: <CadEscala />, },
      { path: 'documentos', element: <Documentos /> },
      { path: 'documentos/cadastro', element: <ManifestoForm /> },
      { path: 'documentos/:doc', element: <ManifestoForm /> },
      { path: 'documentos/:Escala/:Manifesto', element: <ManifestoForm /> },
      { path: 'documentos/:Escala/:Manifesto/:Ce', element: <CeMercanteForm /> },
      { path: 'empresas', element: <Empresas /> },
      {
        path: '/operacao/:ope',
        element: <OpMaritma />,
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <ResumoOpe /> },
          { path: 'resumo', element: <ResumoOpe /> },
          { path: 'descarga', element: <DescMaritma /> },
          { path: 'sof', element: <Statement /> },
          { path: 'cop', element: <RelCop /> },
        ],
      }
    ],
  }, {
    path: '/acesso',
    element: <Acesso />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Login /> },
      { path: 'Dashboard', element: <Dashboard /> },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

reportWebVitals(sendToVercelAnalytics);
